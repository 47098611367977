export default defineNuxtPlugin(() => {
  var piAId = '903071'
  var piCId = ''
  var piDomain = 'go.indocedge.com'

  var s = document.createElement('script')
  s.type = 'text/javascript'
  s.async = true
  s.src = '//' + piDomain + '/pdt.js'
  var c = document.getElementsByTagName('script')[0]
  c.parentNode.insertBefore(s, c)
  // eslint-disable-next-line dot-notation
  window['pdt'] =
    window['pdt'] ||
    function () {
      ;(window['pdt'].cq = window['pdt'].cq || []).push(arguments)
    }

  // Creating an analytics tracker requires the following:
  // pdt('create', <pardot account id + 1000>, <pardot campaign id + 1000>, 'go.example.com');
  window['pdt']('create', piAId, piCId, piDomain)

  const pdt = window['pdt']

  return { provide: { pdt } }
})
