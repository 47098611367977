import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["app","articles","article","SectionBlocks","categories","Page","Image","CallToAction","Highlight","MediaTip","ReadAlso","HeroBlocks","index","pages","page"]}
export const GqlApp = (...params) => useGql()('app', ...params)
export const GqlArticle = (...params) => useGql()('article', ...params)
export const GqlArticles = (...params) => useGql()('articles', ...params)
export const GqlCategories = (...params) => useGql()('categories', ...params)
export const GqlIndex = (...params) => useGql()('index', ...params)
export const GqlPage = (...params) => useGql()('page', ...params)
export const GqlPages = (...params) => useGql()('pages', ...params)