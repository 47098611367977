<template>
  <div v-if="data">
    <NuxtLayout :data="data">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useBlogStore } from './stores/BlogStore'
const store = useBlogStore()
const config = useRuntimeConfig()

const { locale } = useI18n()

const { data } = await useAsyncGql('app', {
  // @ts-ignore
  locale,
  options: {
    initialCache: false
  }
})

if (data.value) {
  store.pushCategories(data?.value!.allCategories)
}

useSchemaOrg([
  defineOrganization({
    logo: `${config.public.appUrl}/public/images/logo.svg`,
    url: config.public.appUrl
  })
])
</script>
