import { default as _91slug_93RPYB4NvKKjMeta } from "/opt/build/repo/pages/[slug].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91slug_932ScwyNT82NMeta } from "/opt/build/repo/pages/knjiznica/[slug].vue?macro=true";
export default [
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___sl",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/:slug()",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___en",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/en/:slug",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___hr",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/hr/:slug",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___sl",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___en",
    path: indexlaFeZuYZkUMeta?.path ?? "/en",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___hr",
    path: indexlaFeZuYZkUMeta?.path ?? "/hr",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_932ScwyNT82NMeta?.name ?? "knjiznica-slug___sl",
    path: _91slug_932ScwyNT82NMeta?.path ?? "/knjiznica/:slug()",
    meta: _91slug_932ScwyNT82NMeta || {},
    alias: _91slug_932ScwyNT82NMeta?.alias || [],
    redirect: _91slug_932ScwyNT82NMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/knjiznica/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932ScwyNT82NMeta?.name ?? "knjiznica-slug___en",
    path: _91slug_932ScwyNT82NMeta?.path ?? "/en/library/:slug",
    meta: _91slug_932ScwyNT82NMeta || {},
    alias: _91slug_932ScwyNT82NMeta?.alias || [],
    redirect: _91slug_932ScwyNT82NMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/knjiznica/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932ScwyNT82NMeta?.name ?? "knjiznica-slug___hr",
    path: _91slug_932ScwyNT82NMeta?.path ?? "/hr/knjiznica/:slug",
    meta: _91slug_932ScwyNT82NMeta || {},
    alias: _91slug_932ScwyNT82NMeta?.alias || [],
    redirect: _91slug_932ScwyNT82NMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/knjiznica/[slug].vue").then(m => m.default || m)
  }
]