import { defineNuxtPlugin } from '#app/nuxt'
import { LazyHeading, LazyHighlight, LazyHighlightList, LazyMediaTip, LazySectionAccordion, LazySectionBody, LazySectionContactForm, LazySectionCta, LazySectionCtaNarrow, LazySectionFeatures, LazySectionFeaturesMedia, LazySectionHeroA, LazySectionHeroB, LazySectionHeroC, LazySectionHeroHTML, LazySectionImageGallery, LazySectionMedia, LazySectionMediaWide, LazySectionNewsletter, LazySectionPartners, LazySectionPlanComparison, LazySectionTabs, LazySectionTestimonials, LazySectionTextBlocks, LazySectionThankYou, LazySectionTitle, LazySectionVideo, LazyCTA, LazyCallToAction, LazySectionArticles, LazySectionRelatedArticles, LazySectionFaq } from '#components'
const lazyGlobalComponents = [
  ["Heading", LazyHeading],
["Highlight", LazyHighlight],
["HighlightList", LazyHighlightList],
["MediaTip", LazyMediaTip],
["SectionAccordion", LazySectionAccordion],
["SectionBody", LazySectionBody],
["SectionContactForm", LazySectionContactForm],
["SectionCta", LazySectionCta],
["SectionCtaNarrow", LazySectionCtaNarrow],
["SectionFeatures", LazySectionFeatures],
["SectionFeaturesMedia", LazySectionFeaturesMedia],
["SectionHeroA", LazySectionHeroA],
["SectionHeroB", LazySectionHeroB],
["SectionHeroC", LazySectionHeroC],
["SectionHeroHTML", LazySectionHeroHTML],
["SectionImageGallery", LazySectionImageGallery],
["SectionMedia", LazySectionMedia],
["SectionMediaWide", LazySectionMediaWide],
["SectionNewsletter", LazySectionNewsletter],
["SectionPartners", LazySectionPartners],
["SectionPlanComparison", LazySectionPlanComparison],
["SectionTabs", LazySectionTabs],
["SectionTestimonials", LazySectionTestimonials],
["SectionTextBlocks", LazySectionTextBlocks],
["SectionThankYou", LazySectionThankYou],
["SectionTitle", LazySectionTitle],
["SectionVideo", LazySectionVideo],
["CTA", LazyCTA],
["CallToAction", LazyCallToAction],
["SectionArticles", LazySectionArticles],
["SectionRelatedArticles", LazySectionRelatedArticles],
["SectionFaq", LazySectionFaq],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
