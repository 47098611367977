// @ts-nocheck


export const localeCodes = [
  "sl",
  "en",
  "hr"
]

export const localeMessages = { 
  "sl": [
      { key: "../locales/sl.json", load: () => import("../locales/sl.json" /* webpackChunkName: "locale__opt_build_repo_locales_sl_json" */), cache: true },
  ],
  "en": [
      { key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "locale__opt_build_repo_locales_en_json" */), cache: true },
  ],
  "hr": [
      { key: "../locales/hr.json", load: () => import("../locales/hr.json" /* webpackChunkName: "locale__opt_build_repo_locales_hr_json" */), cache: true },
  ],
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = {
  "experimental": {
    "jsTsFormatResource": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n/i18n.config.ts",
  "locales": [
    {
      "code": "sl",
      "iso": "sl-SI",
      "name": "Slovenščina",
      "files": [
        "locales/sl.json"
      ]
    },
    {
      "code": "en",
      "iso": "en-US",
      "name": "English",
      "files": [
        "locales/en.json"
      ]
    },
    {
      "code": "hr",
      "iso": "hr",
      "name": "Hrvatski",
      "files": [
        "locales/hr.json"
      ]
    }
  ],
  "defaultLocale": "sl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales",
  "rootRedirect": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://www.indocedge.com",
  "dynamicRouteParams": true,
  "customRoutes": "config",
  "pages": {
    "[slug]": {
      "en": "/[slug]",
      "hr": "/[slug]"
    },
    "knjiznica": {
      "en": "/library",
      "hr": "/knjiznica"
    },
    "knjiznica/[slug]": {
      "en": "/library/[slug]",
      "hr": "/knjiznica/[slug]"
    }
  },
  "skipSettingLocaleOnNavigate": true,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}
  
  const vueI18nConfigLoader = async loader => {
    const config = await loader().then(r => r.default || r)
    if (typeof config === 'object') return config
    if (typeof config === 'function') return await config()
    return {}
  }

  const deepCopy = (src, des, predicate) => {
    for (const key in src) {
      if (typeof src[key] === 'object') {
        if (!(typeof des[key] === 'object')) des[key] = {}
        deepCopy(src[key], des[key], predicate)
      } else {
        if (predicate) {
          if (predicate(src[key], des[key])) {
            des[key] = src[key]
          }
        } else {
          des[key] = src[key]
        }
      }
    }
  }
  
  const mergeVueI18nConfigs = async (loader) => {
    const layerConfig = await vueI18nConfigLoader(loader)
    const cfg = layerConfig || {}
    
    for (const [k, v] of Object.entries(cfg)) {
      if(nuxtI18nOptions.vueI18n?.[k] === undefined || typeof nuxtI18nOptions.vueI18n?.[k] !== 'object') {
        nuxtI18nOptions.vueI18n[k] = v
      } else {
        deepCopy(v, nuxtI18nOptions.vueI18n[k])
      }
    }
  }

  nuxtI18nOptions.vueI18n = { messages: {} }
  
  await mergeVueI18nConfigs(() => import("../i18n/i18n.config.ts?hash=5b32d2e0&config=1" /* webpackChunkName: __i18n_i18n_config_ts_5b32d2e0 */)) 
    
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "jsTsFormatResource": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "sl",
      "iso": "sl-SI",
      "name": "Slovenščina",
      "files": [
        {
          "path": "locales/sl.json"
        }
      ]
    },
    {
      "code": "en",
      "iso": "en-US",
      "name": "English",
      "files": [
        {
          "path": "locales/en.json"
        }
      ]
    },
    {
      "code": "hr",
      "iso": "hr",
      "name": "Hrvatski",
      "files": [
        {
          "path": "locales/hr.json"
        }
      ]
    }
  ]
}
 
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true
